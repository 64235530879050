<template>
  <div v-html="html" v-highlight class="markdown-body"></div>
</template>

<script>
import marked from 'marked'
import './markdown.css'

export default {
  name: 'Markdown',

  props: {
    raw: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      html: '',
      anchors: [],
      tocTokens: []
    }
  },

  watch: {
    raw: {
      handler () {
        this.setMarked(this.raw)
        this.$emit('setTocTokens', this.tocTokens)
      },
      immediate: true
    }
  },

  methods: {
    setMarked (mdString) {
      const renderer = new marked.Renderer()

      renderer.tocTokens = []
      renderer.heading = function (text, level, raw, slugger) {
        if (this.options.headerIds) {
          const anchor = this.options.headerPrefix + '-' + slugger.slug(raw)
          this.tocTokens.push({ text, level, anchor })
          return `<h${level} id="${anchor}" class="markdown-body-anchor" index="${this.tocTokens.length - 1}">${text}</h${level}>`
        }
      }
      renderer.code = function (code, infostring, escaped) {
        return `<pre><code class="markdown-body-code">${code}</code></pre>\n`
      }
      renderer.codespan = function (code) {
        return `<code class="markdown-body-code markdown-body-p-code">${code}</code>\n`
      }

      marked.setOptions({
        renderer: renderer,
        headerPrefix: 'md'
      })

      this.html = marked(mdString)
      this.tocTokens = renderer.tocTokens
    }
  }
}

</script>

<!-- Atom One Dark Reasonable -->
<style scoped>
::v-deep .markdown-body-code {
  display: block;
  overflow-x: auto;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  color: #abb2bf;
  background: #282c34;
  font-size: 0.8rem;
  font-family: 'Source Code Pro', Menlo, Consolas, Monaco, monospace;
}
::v-deep .markdown-body-p-code {
  display: inline;
  padding: 0.25em 0.5em;
}
::v-deep .markdown-body-code .hljs-keyword, ::v-deep .markdown-body-code .hljs-operator {
  color: #F92672;
}
::v-deep .markdown-body-code .hljs-pattern-match {
  color: #F92672;
}
::v-deep .markdown-body-code .hljs-pattern-match ::v-deep .markdown-body-code .hljs-constructor {
  color: #61aeee;
}
::v-deep .markdown-body-code .hljs-function {
  color: #61aeee;
}
::v-deep .markdown-body-code .hljs-function ::v-deep .markdown-body-code .hljs-params {
  color: #A6E22E;
}
::v-deep .markdown-body-code .hljs-function ::v-deep .markdown-body-code .hljs-params ::v-deep .markdown-body-code .hljs-typing {
  color: #FD971F;
}
::v-deep .markdown-body-code .hljs-module-access ::v-deep .markdown-body-code .hljs-module {
  color: #7e57c2;
}
::v-deep .markdown-body-code .hljs-constructor {
  color: #e2b93d;
}
::v-deep .markdown-body-code .hljs-constructor ::v-deep .markdown-body-code .hljs-string {
  color: #9CCC65;
}
::v-deep .markdown-body-code .hljs-comment, ::v-deep .markdown-body-code .hljs-quote {
  color: #b18eb1;
  font-style: italic;
}
::v-deep .markdown-body-code .hljs-doctag, ::v-deep .markdown-body-code .hljs-formula {
  color: #c678dd;
}
::v-deep .markdown-body-code .hljs-section, ::v-deep .markdown-body-code .hljs-name, ::v-deep .markdown-body-code .hljs-selector-tag, ::v-deep .markdown-body-code .hljs-deletion, ::v-deep .markdown-body-code .hljs-subst, ::v-deep .markdown-body-code .hljs-tag {
  color: #e06c75;
}
::v-deep .markdown-body-code .hljs-literal {
  color: #56b6c2;
}
::v-deep .markdown-body-code .hljs-string, ::v-deep .markdown-body-code .hljs-regexp, ::v-deep .markdown-body-code .hljs-addition, ::v-deep .markdown-body-code .hljs-attribute, ::v-deep .markdown-body-code .hljs-meta-string {
  color: #98c379;
}
::v-deep .markdown-body-code .hljs-built_in, ::v-deep .markdown-body-code .hljs-class ::v-deep .markdown-body-code .hljs-title {
  color: #e6c07b;
}
::v-deep .markdown-body-code .hljs-attr, ::v-deep .markdown-body-code .hljs-variable, ::v-deep .markdown-body-code .hljs-template-variable, ::v-deep .markdown-body-code .hljs-type, ::v-deep .markdown-body-code .hljs-selector-class, ::v-deep .markdown-body-code .hljs-selector-attr, ::v-deep .markdown-body-code .hljs-selector-pseudo, ::v-deep .markdown-body-code .hljs-number {
  color: #d19a66;
}
::v-deep .markdown-body-code .hljs-symbol, ::v-deep .markdown-body-code .hljs-bullet, ::v-deep .markdown-body-code .hljs-link, ::v-deep .markdown-body-code .hljs-meta, ::v-deep .markdown-body-code .hljs-selector-id, ::v-deep .markdown-body-code .hljs-title {
  color: #61aeee;
}
::v-deep .markdown-body-code .hljs-emphasis {
  font-style: italic;
}
::v-deep .markdown-body-code .hljs-strong {
  font-weight: bold;
}
::v-deep .markdown-body-code .hljs-link {
  text-decoration: underline;
}
::v-deep .markdown-body-code .hljs-built_in {
  color: #abb2bf;
}
</style>
