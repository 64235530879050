<template>
  <v-row ref="scrollTarget" v-scroll="scrollWithToc" v-if="isPc">
    <v-col :class="hasToc ? 'col-8' : 'col-12'">
      <Markdown ref="markdown" :raw="content" @setTocTokens="setTocTokens"></Markdown>
    </v-col>
    <v-col class="col-4" v-if="hasToc">
      <div ref="rightContainer" style="position: relative; right: 0; top:0">
        <MarkdownToc ref="markdownToc" :tocTokens="tocTokens" style="border-left: 1px solid #aaa;"></MarkdownToc>
      </div>
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col>
      <Markdown :raw="content" @setTocTokens="setTocTokens"></Markdown>
    </v-col>
    <v-btn v-move-drag x-small depressed class="rounded-l-xl" @click="drawer = !drawer" style="right: 0; border: 1px solid rgba(222,229,231,.6); height: 40px;">
      <v-icon small >fa-bars</v-icon>
    </v-btn>
    <v-navigation-drawer right fixed temporary v-model="drawer" v-show="drawer" class="py-4">
      <MarkdownToc :tocTokens="tocTokens"></MarkdownToc>
    </v-navigation-drawer>
  </v-row>
</template>

<script>
import MarkdownToc from '@/components/Markdown/MarkdownToc.vue'
import Markdown from '@/components/Markdown/Markdown.vue'

export default {
  name: 'BlogMarkdown',
  components: {
    Markdown,
    MarkdownToc
  },

  props: {
    content: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      drawer: false,
      tocTokens: [],
      anchors: [],
      tocs: [],
      currentIndex: 0
    }
  },

  computed: {
    isPc () {
      return this.$vuetify.breakpoint.smAndUp
    },

    hasToc () {
      return this.tocTokens.length > 0
    }
  },

  methods: {
    scrollWithToc (e) {
      this.relativeToc()
      this.toggleMarkdownTocCurrent()
    },

    relativeToc () {
      if (!this.hasToc) {
        return
      }

      const scrollTargetOffsetTop = this.$refs.scrollTarget.offsetTop
      const documentScrollTop = document.documentElement.scrollTop - 68
      const top = documentScrollTop < scrollTargetOffsetTop ? 0 : documentScrollTop
      this.$refs.rightContainer.style.top = `${top}px`
    },

    toggleMarkdownTocCurrent () {
      if (!this.hasToc) {
        return
      }

      const distance = 80
      if (top(this.anchors[this.currentIndex]) < distance) {
        if (this.currentIndex > this.anchors.length - 2) {
          return
        }
        if (top(this.anchors[this.currentIndex + 1]) > distance) {
          return
        }
        toggleFontColor(this.tocs, this.currentIndex, ++this.currentIndex)
      } else {
        if (this.currentIndex < 1) {
          return
        }
        // 一旦 now > distance 就已经进入上一个标题内容了
        toggleFontColor(this.tocs, this.currentIndex, --this.currentIndex)
      }

      function top (e) {
        return e.getBoundingClientRect().top
      }

      function toggleFontColor (dom, last, now) {
        dom[last].classList.remove('markdown-toc-anchor-selected')
        dom[now].classList.add('markdown-toc-anchor-selected')
      }
    },

    setTocTokens (value) {
      this.tocTokens = value
      if (this.isPc && this.hasToc) {
        this.$nextTick(() => {
          this.anchors = this.$refs.markdown.$el.querySelectorAll('.markdown-body-anchor')
          this.tocs = this.$refs.markdownToc.$el.querySelectorAll('.markdown-toc-anchor')
          this.tocs[this.currentIndex].classList.add('markdown-toc-anchor-selected')
        })
      }
    }
  }
}

</script>

<style scoped>

</style>
