<template>
  <v-container v-if="blog" class="py-5 px-8">
    <h1>{{ blog.title }}</h1>
    <v-row class="d-flex no-gutters align-center">
      <IconText icon="fa-calendar-plus-o" :text="blog.createdAt" class="mr-2"></IconText>
      <IconText icon="fa-calendar-check-o" :text="blog.updatedAt" class="mr-sm-6"></IconText>
      <div class="d-flex align-center">
        <v-chip label v-if="blog.Category" class="mr-5"><span>{{ blog.Category.name }}</span></v-chip>
        <LabelChip :labels="blog.Labels"></LabelChip>
      </div>
    </v-row>
    <BlogMarkdown :content="content"></BlogMarkdown>
  </v-container>
</template>

<script>
import BlogMarkdown from '@/components/Blog/BlogMarkdown.vue'
import IconText from '@/components/base/IconText.vue'
import LabelChip from '@/components/Label/LabelChip.vue'

export default {
  name: 'BlogDetail',
  components: {
    BlogMarkdown,
    IconText,
    LabelChip
  },

  props: {
    id: undefined
  },

  data () {
    return {
      content: '',
      blog: null
    }
  },

  computed: {
    isPc () {
      return this.$vuetify.breakpoint.smAndUp
    }
  },

  created () {
    this.getBlog()
  },

  methods: {
    async getBlog () {
      const blog = await this.$http.Blog.get(this.id)
      this.blog = blog
      this.content = blog ? blog.content : ''
    }
  }
}

</script>

<style scoped>

</style>
