<template>
  <v-chip-group column>
    <v-chip
      @click.prevent="goDetail(label.id)"
      v-for="label in labels"
      :key="label.id">
      {{ label.value }}
    </v-chip>
  </v-chip-group>
</template>

<script>
export default {
  name: 'LabelChip',
  props: {
    labels: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    goDetail (id) {
      console.log(id)
    }
  }
}
</script>
